<template>
  <div class="container mt-2">
    <app-title title="My profile" :underline="false" />
    <div class="row mt-4 ps-4">
      <table class="col-12 profile-table">
        <tbody>
          <tr v-for="field in profile">
            <td>{{ field.label }}</td>
            <td>{{ user[field.name] }}</td>
            <td class="icon-td">
              <i
                class="bi bi-pencil-fill"
                v-if="field.name !== 'email'"
                @click="edit(field)"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-12 text-end mt-4">
        <button class="btn-sm-grey" @click="$router.push({ name: 'Password' })">
          Reset password
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { profile } from '@/utils/config/user';
import { ElMessageBox } from 'element-plus';
import Database from '@/utils/services/Database';

export default {
  name: 'UserProfile',
  data() {
    return {
      user: {},
      profile,
    };
  },
  async created() {
    const user = await this.$store.dispatch('getUser');
    this.user = user;
  },
  methods: {
    edit(field) {
      ElMessageBox.prompt(`Update ${field.label.toLowerCase()}`, {
        confirmButtonText: 'Update',
        cancelButtonText: 'Cancel',
        inputPattern: field.pattern,
        inputErrorMessage: field.errorMessage,
      })
        .then(({ value }) => {
          if (value) {
            this.update(field.name, value);
          }
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: "An error occurred while updating your profile",
            offset: 200
          });
        });
    },
    async update(fieldName, value) {
      const status = await Database.updateUser({ [fieldName]: value });

      if (status === 200) {
        this.$message({
          type: 'success',
          message: 'Your profile has been updated successfully.',
          offset: 200
        });

        this.user[fieldName] = value;
      } else {
        this.$message({
          type: 'error',
          message: "An error occurred while updating your profile",
          offset: 200
        });
      }
    },
  },
};
</script>

<style lang="scss">
.profile-table {
  tbody {
    tr {
      td {
        padding: calc($sp/2);
        border: 1px solid $light-grey;
        &.icon-td {
          text-align: center;
          i {
            color: $dark-grey;
            cursor: pointer;
            transition: $trs-default;
            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }
}
</style>
